<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Application" />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Ajouter une application</div>
              </v-row>
            </v-card-title>

            <!-- le label -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">label</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le label"
                    dense
                    v-model="label"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>


            <!-- le nom digital -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">
                    nom digital
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="le nom digital"
                    dense
                    v-model="digitalName"
                    @blur="digitalNameFieldOut"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>


            <!-- les mots clés -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">mots clés</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Les mots clés"
                    dense
                    v-model="keywords"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>


            <!-- is group -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">
                    app collaborateur
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-checkbox 
                  on-icon="mdi-checkbox-outline"
                  off-icon="mdi-checkbox-blank-outline"
                  v-model="isGroup"></v-checkbox>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>


            <!-- le service -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">service</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-autocomplete
                    placeholder="Sélectionner un service"
                    :items="services"
                    item-text="name"
                    item-value="id"
                    no-data-text="aucun service"
                    v-model="serviceId"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- La catégorie dans le service -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">catégorie</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-combobox
                    placeholder="Sélectionner ou saisir une catégorie"
                    :items="availableCategory"
                    item-text="category"
                    item-value="category"
                    no-data-text="aucune catégorie"
                    v-model="serviceCategory"
                    clearable
                  >
                  </v-combobox>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- L'ordre dans la catégorie -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">ordre dans la catégorie</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Ordre"
                    dense
                    v-model="ranking"
                    type="number"
                  :rules="[rules.positive]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">nouveauté</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-checkbox 
                  on-icon="mdi-checkbox-outline"
                  off-icon="mdi-checkbox-blank-outline"
                  v-model="isnew"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">url</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Url de l'application"
                    dense
                    v-model="url"
                    :rules="[rules.validate]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">actif</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-checkbox 
                  on-icon="mdi-checkbox-outline"
                  off-icon="mdi-checkbox-blank-outline"
                  v-model="isactive"></v-checkbox>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">ouvrir dans un nouvel onglet</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-checkbox 
                  on-icon="mdi-checkbox-outline"
                  off-icon="mdi-checkbox-blank-outline"
                  v-model="openinnewtab"></v-checkbox>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- nom de l'icone -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">nom de l'icône</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Nom de l'icône"
                    dense
                    v-model="iconName"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Position dans le menu -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">ordre dans le menu</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Ordre menu"
                    dense
                    v-model="menuRanking"
                    type="number"
                    :rules="[rules.positive]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>           

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2  px-4 btn"
                color="primary"
                :disabled="!canSave"
                @click="save"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import { ApplicationService } from "@/service/dictionary/applications_service.js";
import ServicesService from "@/service/sfr/services_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { checkUrl } from "@/tools/url_tool.js";
import { trimString } from "@/tools/string_tool.js";

export default {
  name: "AddApplication",
  components: { Workflow, TitleAndReturnComponent },

  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      service: null,

      /**le service d'accès aux services */
      serviceServices: null,

      /** le label */
      label: null,

      /** le nom digital */
      digitalName: null,

      /** les mots clés */
      keywords: null,

      /** est-ce une app du groupe */
      isGroup: null,

      /**la liste des services */
      services: [],

      /**identifiant du service associé  */
      serviceId: null,

      /**l'ordre d'affichage dans la catégorie */
      ranking: null,

      /**est une nouveauté */
      isnew: null,

      /**l'url de l'application */
      url: null,

      /**application activée dans le lanceur */
      isactive: null,

      /** Appllication doit être ouverte dans un nouvel onglet */
      openinnewtab: null,

      /** La catégorie de l'application */
      serviceCategory: null,

      /** Nom de l'icone */
      iconName: null,

      /** Position dans le menu */
      menuRanking: null,

      /** la liste des categories */
      categories: [],


      rules: {
        validate: (value) =>
          checkUrl(value) || "veuillez saisir une url valide",
        positive: (value) => value >= 0 || "pas de valeur négative",
      },
    };
  },
  methods: {
    async load() {
      this.services = await this.serviceServices.getAllServices();

      // Récupère toutes les applications
        let apps = await this.service.getAll();
        // Parcours les applications 
        for (let app of apps) {          
          let foundCat = this.categories.find((c) => c.category == app.serviceCategory && c.serviceId == app.serviceId);

          if (!foundCat) {
            this.categories.push({
              serviceId: app.serviceId,
              category: app.serviceCategory,
            });
          }
        }

    },
    async save() {
      // enregistrement de l'acronyme
      if (this.entityHasChanged) {
        try {
          let entity = {};
          entity.label = this.label;
          entity.digitalName = this.digitalName;
          entity.keywords = this.keywords;
          entity.isgroup = this.isGroup;

          entity.serviceId = this.serviceId;
          entity.ranking = this.ranking;
          entity.isnew = this.isnew;
          entity.url = this.url;
          entity.isactive = this.isactive;
          entity.openinnewtab = this.openinnewtab;
          entity.iconName = this.iconName;
          entity.menuRanking = this.menuRanking;
          entity.serviceCategory = this.serviceCategory;

          await this.service.create(entity);

          this.$router.go(-1);
        } catch (error) {
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement de l'application : " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      }
    },

    /** Méthode appelée lors de l'évènement de sortie de champ nom digital */
    digitalNameFieldOut() {
      this.digitalName = trimString(this.digitalName);
    },
  },
  computed: {
    canSave() {
      try {
        if (!this.label || this.label == "") {
          return false;
        }
        if (!this.digitalName || this.digitalName == "") {
          return false;
        }

        // vérification de la présence du service retirée le 14/01/2012 par SR
        // if (!this.serviceId) {
        //   return false;
        // }

        if (this.url) {
          if (!checkUrl(this.url)) {
            return false;
          }
        }

        if (this.ranking) {
          if (this.ranking < 0) {
            return false;
          }
        }
        if (this.menuRanking) {
          if (this.menuRanking < 0) {
            return false;
          }
        }

        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },

    /**retourne true si entité est modifié */
    entityHasChanged() {
      let changed = false;

      if (this.label) {
        changed = true;
      }
      if (this.digitalName) {
        changed = true;
      }
      if (this.keywords) {
        changed = true;
      }
      if (this.isGroup) {
        changed = true;
      }

      if (this.serviceId) {
        changed = true;
      }

      return changed;
    },

    /** Met à disposition les catégories  */
    availableCategory() {
      let items = [];

      if (this.serviceId) {
        let itemsCat = this.categories.filter((c) => c.serviceId == this.serviceId && c.category != null);
        items = itemsCat.map((i) => i.category);
      }

      return items;
    },
  },
  mounted() {
    //instanciation des services d'accès aux APIs
    this.service = new ApplicationService(this.$api.getApplicationApi());

    this.serviceServices = new ServicesService(this.$api);

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>